import React from 'react';
import profileImage from './assets/noel.jpg';

export default function Home () {
    return (
        <div style={{width: '100%', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
            <h1>Noel Raley</h1>
            <img style={{borderRadius: '5% 10% 5% 10%', boxShadow: '-2px 2px 4px grey'}} src={profileImage} width="250" height="250"/>
            <p>
                I am a software developer located in South Carolina, working remotely.
            </p>
        </div>
    );
};