import React from 'react';
import { Button } from '@material-ui/core';
import { 
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";

import Countries from 'Countries';
import States from 'States';
import Beers from 'Beers';
import Home from 'Home';

function App() {
  return (
    <Router>
      <div style={{display: 'flex', flexDirection: 'column', height: '100%'}}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            background: '#9ab9e4',
            boxShadow: '-1px 2px 10px grey',
            maxHeight: 52,
            fontSize: 12,
          }}
        >
            <Link
              style={{
                background: '#2757af',
                color: 'white',
                margin: 8,
                fontWeight: 600,
                width: 100,
              }}
              component={Button}
              to="/"
            >
                Home
            </Link>
            <Link
              style={{
                background: '#2757af',
                color: 'white',
                margin: 8,
                fontWeight: 600,
                width: 100,
              }}
              component={Button}
              to="/states"
            >
              States
            </Link>
            <Link
              style={{
                background: '#2757af',
                color: 'white',
                margin: 8,
                fontWeight: 600,
                width: 100,
              }}
              component={Button}
              to="/countries"
            >
              Countries
            </Link>
            <Link
              style={{
                background: '#2757af',
                color: 'white',
                margin: 8,
                fontWeight: 600,
                width: 100,
              }}
              component={Button}
              to="/beers"
            >
              Beers
            </Link>
        </div>
        <div
          style={{
            display: 'flex',
            flex: 1,
            background: '#ebf2fc',
            maxHeight: 'calc(100% - 52px)',
          }}
        >
          <Switch>
            <Route path="/states">
              <States/>
            </Route>
            <Route path="/countries">
              <Countries/>
            </Route>
            <Route path="/beers">
              <Beers/>
            </Route>
            <Route path="/">
              <Home/>
            </Route>
          </Switch>
        </div>
      </div>
    </Router>
  );
}

export default App;
