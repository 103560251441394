import React, {useRef, useState} from 'react';
import USAMap from "react-usa-map";
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

export default function States () {
    const [showAirportStates, setShowAirportStates] = useState(true);
    const visitedStates = ['AK', 'CA', 'DC2', 'FL', 'GA', 'IN', 'KY', 'MI', 'NC', 'OH', 'SC', 'TN', 'VA', 'WV', 'MA', 'IL', 'NY', 'AZ'];
    const optionalStates = ['TX', 'NJ', 'WA', 'PA'];
    
    const mapHandler = (event) => {
        console.log(event.target.dataset.name);
    };

    let fullStates = visitedStates;
    if (showAirportStates) {
        fullStates = [...visitedStates, ...optionalStates];
    }
    const mapConfig = fullStates.reduce((acc, curr) => (acc[curr] = {fill: 'green'}, acc), {});

    return (
        <div style={{width: '100%', display: 'flex', flexDirection: 'column', overflowY: 'auto'}}>
            <h1 style={{textAlign: 'center'}}>States I've Visited: {fullStates.length}</h1>
            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center',}}>
                <FormControlLabel
                    control={
                        <Checkbox
                            color="primary"
                            checked={showAirportStates}
                            onChange={(event) => {console.log(event.target.checked); setShowAirportStates(event.target.checked)}}
                            name="showAirportStates"
                        />
                    }
                    label="Include states only visited in airports"
                />
            </div>
            <div
                style={{
                    display: 'flex',
                    flex: 1,
                    justifyContent: 'center',
                    maxHeight: 'calc(100% - 147px)',
                    minHeight: 500,
                }}
            >
                <USAMap width='100%' height='100%' style={{width: '100%', height: '100%'}} onClick={mapHandler} customize={mapConfig}/>
            </div>
        </div>
    );
}
