import React, {useRef, useState} from 'react';
import USAMap from "react-usa-map";
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { SVGMap } from 'react-svg-map';
import World from '@svg-maps/world';
import "react-svg-map/lib/index.css";
import "worldmap.css";

export default function Countries () {
    const [showAirportCountries, setShowAirportCountries] = useState(true);
    const visitedCountries = ['us', 'co', 'es', 'fr', 'it', 'ch', 'be', 'gb', 'de', 'at', 'se', 'no', 'dk'];
    const airportCountries = ['fi']

    let fullCountries = visitedCountries;
    if (showAirportCountries) {
        fullCountries = [...fullCountries, ...airportCountries];
    }
    const calculateIsSelected = (location, index) => {
        return fullCountries.indexOf(location.id) !== -1;
    };
    return (
        <div style={{width: '100%', display: 'flex', flexDirection: 'column', overflowY: 'auto',}}>
            <h1 style={{textAlign: 'center'}}>Countries I've Visited: {fullCountries.length}</h1>
            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center',}}>
                <FormControlLabel
                    control={
                        <Checkbox
                            color="primary"
                            checked={showAirportCountries}
                            onChange={(event) => {console.log(event.target.checked); setShowAirportCountries(event.target.checked)}}
                            name="showAirportStates"
                        />
                    }
                    label="Include countries only visited in airports"
                />
            </div>
            <div style={{display: 'flex', flex: 1, justifyContent: 'center', maxHeight: 'calc(100% - 147px)', minHeight: 500}}>
                <SVGMap map={World} isLocationSelected={calculateIsSelected}/> 
            </div>
        </div>
    );
}