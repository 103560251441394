import React, { useEffect, useState } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import axios from 'axios';

export default function Countries () {
    const [beers, setBeers] = useState([]);
    const [totalBeers, setTotalBeers] = useState('...');
    const [orderBy, setOrderBy] = useState('');
    const [order, setOrder] = useState('asc');

    const fields = [
        {
            id: 'label',
            name: 'Label',
        },
        {
            id: 'name',
            name: 'Name',
        },
        {
            id: 'brewery',
            name: 'Brewery',
        },
        {
            id: 'rating',
            name: 'Rating',
        },
        {
            id: 'last_checkin',
            name: 'Last Check-In',
        },
    ];

    function descendingComparator(a, b, orderBy) {
        if (b[orderBy] < a[orderBy]) {
          return -1;
        }
        if (b[orderBy] > a[orderBy]) {
          return 1;
        }
        return 0;
    };

    function getComparator(order, orderBy) {
        return order === 'desc'
          ? (a, b) => descendingComparator(a, b, orderBy)
          : (a, b) => -descendingComparator(a, b, orderBy);
    };

    function stableSort(array, comparator) {
        const stabilizedThis = array.map((el, index) => [el, index]);
        stabilizedThis.sort((a, b) => {
            const order = comparator(a[0], b[0]);
            if (order !== 0) return order;
            return a[1] - b[1];
        });
        return stabilizedThis.map((el) => el[0]);
    }

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const createSortHandler = (property) => (event) => {handleRequestSort(event, property);};

    useEffect(() => {
        (async () => {
            let nextURL = 'https://api.untappd.com/v4/user/beers/ncraley';
            let beers = [];
            let totalBeers = '...';
            while(true) {
                try {
                    const res = await axios.get(nextURL, {
                        params: {
                            client_id: 'E3AB1F11FC8848CA4F6E232B59F7AAA0FCDD93BF',
                            client_secret: '12BF27831C202BA2DE1FD15F4893322184B87F11',
                            limit: 50,
                        }
                    });
                    if (res && res.data && res.data.response && res.data.response.beers) {
                        beers = [...beers, ...res.data.response.beers.items];
                        nextURL = res.data.response.pagination.next_url;
                        totalBeers = res.data.response.total_count;
                    }
                    else {
                        break;
                    }
                }
                catch (err) {
                    break;
                }
            }
            setBeers(beers.map(item => ({
                label: item.beer.beer_label,
                id: item.beer.bid,
                name: item.beer.beer_name,
                brewery: item.brewery.brewery_name,
                rating: item.rating_score,
                last_checkin: new Date(item.recent_created_at),
            })));
            setTotalBeers(totalBeers);
        })();
    }, []);

    return (
        <div style={{width: '100%', padding: 64, display: 'flex', flexDirection: 'column', overflowY: 'auto',}}>
            <h1 style={{textAlign: 'center'}}>Beers I've had: {totalBeers}</h1>
            <TableContainer component={Paper}>
                <Table aria-label="simple table" stickyHeader>
                    <TableHead>
                        <TableRow>
                            {fields.map(field =>
                            (<TableCell style={{fontWeight: 'bold'}}>
                                {
                                    field.id === 'label' ?
                                    field.name
                                    :
                                    <TableSortLabel
                                        active={orderBy === field.id}
                                        direction={orderBy === field.id ? order : 'asc'}
                                        onClick={createSortHandler(field.id)}
                                    >
                                        {field.name}
                                        {orderBy === field.id ? (
                                            <span style={{display: 'none'}}>
                                            {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                            </span>
                                        ) : null}
                                    </TableSortLabel>
                                }
                            </TableCell>)    
                            )}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                    {stableSort(beers, getComparator(order, orderBy)).map((item) => (
                        <TableRow key={item.id}>
                            <TableCell component="th" scope="row">
                                <img width="50" height="50" src={item.label}/>
                            </TableCell>
                            <TableCell>{item.name}</TableCell>
                            <TableCell>{item.brewery}</TableCell>
                            <TableCell>{item.rating}</TableCell>
                            <TableCell>{item.last_checkin.toString().substring(0, item.last_checkin.toString().length - 23)}</TableCell>
                        </TableRow>
                    ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
}